<template>
  <div class="app-container">
    <div id="app-contain">
      <div id="filter-container" class="filter-container">
                   <el-date-picker  v-model="listQuery.stYear"  type="year" style="width: 200px;" class="filter-item" value-format="yyyy-MM-dd HH:mm:ss"  placeholder="任务年度"> 
  </el-date-picker>
         <el-select v-model="listQuery.state" placeholder="任务状态" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in apiDeployStates"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
       <el-input v-model="listQuery.name" placeholder="任务名称" style="width: 150px;" class="filter-item" clearable />

        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
          查询
        </el-button>
        <el-button style="float: right" class="filter-item" type="primary" icon="el-icon-circle-plus" @click="addReport">
          新增
        </el-button>
      </div>

      <el-table
        v-loading="listLoading"
        :data="list"
        :height="tabheight"
        border
        fit
        highlight-current-row
        style="width: 100%;overflow:auto"
      >
        <el-table-column label="序号" type="index" align="center" width="50x" />
        <el-table-column label="任务名称" header-align="center" align="left" prop="name" min-width="250" />
        <el-table-column label="年度" header-align="center" align="right" prop="stYear" min-width="60" />
        <el-table-column label="开始时间" header-align="center" align="right" prop="beginDate" min-width="200" />
        <el-table-column label="截止时间" header-align="center" align="right" prop="endDate" min-width="200" />
        <!-- <el-table-column label="任务状态" header-align="center" align="left" prop="state" min-width="150" /> -->
        <el-table-column label="任务状态" header-align="center" align="left" width="140">
          <template slot-scope="scope">
            <span>{{ getDicName(scope.row.state,'YW_TASK_STATE') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" header-align="center" align="right" prop="createTimeTi" width="200" show-overflow-tooltip />
        <el-table-column label="创建人" header-align="center" align="left" prop="createUser" width="140" />
        <el-table-column
          fixed="right"
          label="操作"
          width="300"
          header-align="center"
          align="left"
        >
          <template slot-scope="scope">
            <span>

              <el-button type="primary" size="small" @click="view(scope.row)">查看</el-button>
              <el-button type="primary" v-if="scope.row.state=='1' ||scope.row.state=='3' " size="small" @click="editReport(scope.row)">编辑</el-button>
              <el-button type="primary" v-if="scope.row.state=='1'||scope.row.state=='3'" size="small" @click="stateEdit(scope.row,'2','发布')">发布</el-button>
              <el-button type="danger" v-if="scope.row.state=='2'" size="small" @click="stateEdit(scope.row,'3','撤回')">撤回</el-button>
              <el-button type="primary" v-if="scope.row.state=='2' || scope.row.state=='3'" size="small" @click="handleSubmitTest(scope.row)">填报情况</el-button>

            </span>
          </template>
        </el-table-column>

      </el-table>
      <pagination v-show="total>0" id="pagination" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
      <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleAR" width="55%" append-to-body :title="'新增任务'">
        <add-report v-if="dialogVisibleAR" ref="examineindex" :proid="code" :visible.sync="dialogVisibleAR" />
      </el-dialog>
      <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleView" width="60%" append-to-body :title="'任务填报详情'">
        <view-index v-if="dialogVisibleView" ref="view" :proid="code" :visible.sync="dialogVisibleView" />
      </el-dialog>
      <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleReportView" width="60%" append-to-body :title="'任务详情'">
        <report-view v-if="dialogVisibleReportView" ref="view" :proid="code" :visible.sync="dialogVisibleReportView" />
      </el-dialog>
       <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleReportEdit" width="60%" append-to-body :title="'编辑任务'">
        <edit-report v-if="dialogVisibleReportEdit" ref="edit" :proid="code" :visible.sync="dialogVisibleReportEdit" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import { getPage,editTask  } from '@/api/task'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'

import addReport from '../../taskManage/fillingTask/addReport.vue'
import editReport from '../../taskManage/fillingTask/editReport.vue'
import viewIndex from '../../taskManage/fillingTask/view.vue'
import ReportView from '../../taskManage/fillingTask/ReportView.vue'
export default {
  components: { Pagination, addReport, viewIndex,ReportView ,editReport},
  mixins: [tableHeight2],
    provide() {
    return {
      loadList: this.loadList
    }
  },
  data() {
    return {
      filterText: null,
      list: null,
      total: 0,
      listLoading: true,
      dialogVisibleAR: false,
      dialogVisibleReportEdit:false,
      treeLoading: false,
      dialogVisibleView: false,
      dialogVisibleReportView:false,
      dialogVisibleLog: false,
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },

      logQuery: {
        limit: 10,
        page: 1,
        id: null
      },
      code:0,
      apiTypes: [],
      treeList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tabheight: 0,
      logList: [],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      rules: {
        itemId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination - 15
      console.log(this.tabheight, filterHeight, tableHeight, pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination - 15
        })()
      }
    })
  },
  created() {
    this.loadList()
    this.getTableTreeList()
    this.getDictList('YW_TASK_STATE')
  },
  methods: {
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_STATE') {
          this.apiDeployStates = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_STATE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },

    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
    // handleNodeClick(data){
    //   if(data.level==="1"){
    //     this.listQuery.themeId=data.id;
    //     this.loadList();
    //   }
    // },
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    addReport() {
      this.dialogVisibleAR = true
    },
    editReport(row){

      this.code=row.id
      this.dialogVisibleReportEdit = true
      this.$nextTick(() => {
        this.$refs.edit
        this.$refs.edit.getDetailed()
      })
    },
    loadList() {
      this.listLoading = true

      getPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records

        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
      this.listLoading = false
      this.total = parseInt(1)
    },

    stateEdit(row,value,msg) {
      var task={}
      task.id=row.id
      task.state=value
      
        var query={}
        query.task=task
         MessageBox.confirm('确认'+ msg, '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在处理......',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.3)'
        })
      editTask(query).then(response => {
        Message({
            message: msg+"成功",
            type: 'success',
            duration: 5 * 1000
          })

          this.$emit('update:visible', false)
          this.loadList()
          loading.close()
        }).catch(response => {
          loading.close()
        })
        loading.close()
        })
    },
    handleSubmitTest(row) {
      this.code=row.id
      this.dialogVisibleView = true
    },
    view(row) {

     this.code=row.id
      this.dialogVisibleReportView = true
      this.$nextTick(() => {
        this.$refs.view
        this.$refs.view.getDetailed()
      })
    },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    // 树型结构
    getTableTreeList() {
      this.treeLoading = true
      this.treeList = [{
        name: '教育装备填报', children: [
          { name: '表1 学校基本情况统计表', children: [] },
          { name: '表2 实验室管理人员情况统计表', children: [] },
          { name: '表3 实验室及功能教室用房情况统计表', children: [] },
          { name: '表4 教学仪器情况统计表', children: [] },
          { name: '表5 教学设备情况统计表', children: [] },
          { name: '表6 理科实验开出情况统计表', children: [] },
          { name: '表7 图书管理人员情况统计表', children: [] }
        ]
      }]
      this.treeLoading = false
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    }
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
