import request from '@/utils/request'

export function editTask(data) {
  return request({
    url: '/ltcloud/task/editTask',
    method: 'post',
    data
  })
}

export function addTask(data) {
  return request({
    url: '/ltcloud/task/addTask',
    method: 'post',
    data
  })
}
export function getPage(data) {
  return request({
    url: '/ltcloud/task/taskPage',
    method: 'post',
    data
  })
}

export function getSchoolPage(data) {
  return request({
    url: '/ltcloud/task/getSchoolPage',
    method: 'post',
    data
  })
}
export function getInfoTask(taskId) {
  return request({
    url: '/ltcloud/task/getInfoTask',
    method: 'post',
    params: taskId
  })
}

