<template>
  <div style="width:100%;height:auto; margin:0 auto;">
  <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="任务详情" name="first"></el-tab-pane>
      <el-tab-pane label="市实验开出标准设置" name="second"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName==='first'">
      <el-form ref="ruleForm"  :model="task" label-width="140px" label-position="left">
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">任务名称<font color="red">*</font></div>
              <el-input v-model="task.name"  />
            </el-form-item>
          </el-col>
           <el-col :span="11">
            <el-form-item>
              <div slot="label">任务年度<font color="red">*</font></div>
             <el-date-picker  v-model="task.stYear"  type="year"  value-format="yyyy-MM-dd HH:mm:ss" readonly  placeholder="选择年"> 
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">开始时间<font color="red">*</font></div>
              <el-date-picker v-model="task.beginDate" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">截止时间<font color="red">*</font></div>
              <el-date-picker v-model="task.endDate" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="22">
            <el-form-item>
              <div slot="label">备注</div>
              <el-input type="textarea" :rows="5" v-model="task.remarks"   maxlength="200" show-word-limit  />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     
    </div>
    <div v-if="activeName==='second'">
      <el-form ref="ruleForm" :model="form1" label-width="100px" label-position="left">
        <el-descriptions title="小学科学">
        </el-descriptions>
        <el-row :gutter="24">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.psScienceDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">随堂分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.psScienceClassroomGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">实验室分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.psScienceLaboratoryGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-descriptions title="初中物理">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msPhysicsDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msPhysicsGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-descriptions title="初中化学">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msChemistryDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msChemistryGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-descriptions title="初中生物">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msOrganismDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msOrganismClassroomGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">探究<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.msOrganismLaboratoryGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-descriptions title="高中物理">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsPhysicsDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsPhysicsGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-descriptions title="高中化学">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsChemistryDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsChemistryGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-descriptions title="高中生物">
        </el-descriptions>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">演示<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsOrganismDemo" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <div slot="label">分组<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsOrganismClassroomGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="22">
          <el-col :span="11">
            <el-form-item>
              <div slot="label">探究<font color="red">*</font></div>
              <el-input type="number" :min="0" v-model="form1.hsOrganismLaboratoryGrouping" >
                <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getInfoTask } from '@/api/task'

export default {
  name: '',
  inject: ['loadList'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本显示学校教学设备的配备的基本情况，包含小学科学、中学物理、中学化学、中学生物实验室和其附属用房内和思政、史地、音乐、美术、书法、体育、心理健康、信息科技、劳技/通用/综合实践等，以及表中类未体现的功能教室及其附属用房内的设备；网络及外部设备；图书馆/阅览室设备。</br>1.实验室设备和功能教室设备：理科实验室和功能教室及附属用房内的桌、凳、台、柜、厨、通风换气、空调等，以购入的金额为准。</br>2.网络及外部设备：是指不包括计算机在内的投入校园网网络建设的金额之和，以购入时的金额为准；网络及外部设备包括：机柜、服务器、交换机、UPS电源、控制器、路由器、调制解调器（猫）等</br>3.图书馆、阅览室设备：是指学校图书馆、阅览室内所有家具类、办公类设备、流通类、信息化软硬件等，但不包含多媒体及计算机。</br>4.合计：表中前面项目的总和。",
      up1Visible: false,
      up2Visible: false,
      activeName:'first',
      form: {
        userName:'',
        userCompany:''
      },
      task:{},
      xmName: '',
      themeName: '',
      schemaNa: '',
      form1:{},
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '22年教育装备填报'
        }, {
           value: '2',
          label: '22年教育装备填报'
        }, {
           value: '3',
          label: '21年教育装备填报'
        }]
    }
  },
  created() {
    // this.getDictList('PUBLIC_DATA_TYPE')
    // this.getSchemaInfo()
    //this.getDetailed()
  },
  methods: {
     closePage() {
    
          this.$emit('update:visible', false)
          this.loadList()
      
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
     getDetailed() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      getInfoTask({ taskId: this.proid }).then(response => {
        
        this.task = response.data.task
        this.form1=response.data.scienceExperimentStandard
        
        loading.close()
      }).catch(function() {
        loading.close()
      })
      loading.close()
    },
  }
}
</script>
